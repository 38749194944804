<template>
<div class="h-screen flex w-full bg-img p-10" v-if="currentBallot">
    <div class="vx-col sm:w-full md:w-full lg:w-1/2 xl:w-1/4 mx-auto self-center">
        <vx-card title="Boat Harbour Park">
            <div class="vx-row">
                <div class="w-full p-4">
                    <div class="mb-8 text-center">
                        <h4 class="mb-4">Ballot Date: {{ currentBallot.ballotDate | formatLongDate }}</h4>
                        <h5 class="mb-4 text-success" v-if="optedInShow">You have successfully opted In.</h5>
                        <h5 class="mb-4 text-danger" v-if="optedoutShow">You have opted Out.</h5>
                        <p class="mb-base"></p>
                        <vs-button type="border" @click.prevent="cancel" class="w-full mt-base">Login</vs-button>
                        <div v-if="errorMessage" class="pt-5">
                            <vs-alert active="true" color="danger">
                                {{ errorMessage }}
                            </vs-alert>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</div>
</template>

<script>
import {
    ballotData
} from "../../store/api/ballot";

import {
    customMessages,
    passwordValidationMessage
} from './../../filters/validationmessage'

export default {
    data() {
        return {
            optedInShow: false,
            optedoutShow: false,
            currentBallot: null,
            customMessages,
            passwordValidationMessage,
            errorMessage: ""
        }
    },
    computed: {
        BallotRequestId() {
            return this.$route.params.id;
        },
        BallotAction() {
            return this.$route.params.action;
        }
    },
    async created() {
        await this.loadBallot();
        await this.takeAction();

    },
    methods: {
        cancel() {
            this.$router.push("/logout");
        },
        async loadBallot() {
            this.currentBallot = await ballotData.SearchBallotByRequestKey(this.BallotRequestId)
            console.log(this.currentBallot);
        },
        async takeAction() {
            var payload = {
                id: this.BallotRequestId,
                ballotId: this.currentBallot.id,
                actionName: this.BallotAction
            }

            let result = await ballotData.OptInOrOptOut(payload);
            if (!result.succeeded) {
                this.errorMessage = result.message;
                return;
            }

            this.$vs.notify({
                title: "Success",
                text: "Your request has been submitted",
                color: "success",
                position: "top-center"
            });

            if (this.BallotAction === "optin") {
                this.optedInShow = true;
                this.optedoutShow = false;
            }
            if (this.BallotAction === "optout") {
                this.optedInShow = false;
                this.optedoutShow = true;
            }
        }
    }
}
</script>

<style scoped>
.custom-logo-size {
    height: 5rem;
}
</style>
